<template>
  <div class="view-preview-lot-list-dia-log">
    <el-dialog title="编辑单个拍品信息" :visible.sync="dialogVisible" width="40%">
      <div class="lot-info-j">拍品基本信息</div>
      <div class="lot-info-z">
        <el-form ref="editData" :model="editData" label-width="110px" :rules="rules">
          <el-form-item label="拍品Lot号" prop="lot">
            <el-input v-model="editData.lot" placeholder="请输入Lot号" :disabled="editData.auctionIsverify==2"></el-input>
          </el-form-item>
          <el-form-item label="拍品名称" prop="title">
            <el-input v-model="editData.title" placeholder="请输入拍品名称"></el-input>
          </el-form-item>
          <el-form-item label="拍品描述" prop="itemOverview">
            <el-input v-model="editData.itemOverview" placeholder="请输入拍品描述 "></el-input>
          </el-form-item>

          <el-form-item label="起拍价" prop="initialPrice">
            <el-input v-model.number="editData.initialPrice" @blur="changeBlur" placeholder="请输入起拍价"></el-input>
          </el-form-item>

          <el-form-item label="拍品估价" prop="highestValuation">
            <el-input v-model.number="editData.minimumValuation" @blur="changeBlur" style="width:160px"
              placeholder="最低估价"></el-input>
            <span style="padding:0 20px">————</span>
            <el-input v-model.number="editData.highestValuation" @blur="changeBlur" style="width:160px"
              placeholder="最高估价"></el-input>
          </el-form-item>
          <el-form-item label="品牌/作者">
            <div class="select-bottom">
              <el-select v-model="editData.brandAuthorId" placeholder="请选择" style="width:100%">
                <el-option v-for="item in attributeOne" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item label="风格/时期">
            <div class="select-bottom">
              <el-select v-model="editData.stylePeriodId" placeholder="请选择" style="width:100%">
                <el-option v-for="item in attributeTwo" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item label="材料/工艺">
            <div class="select-bottom">
              <el-select v-model="editData.materialsCraftId" placeholder="请选择" style="width:100%">
                <el-option v-for="item in attributeSthree" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item label="拍品分类">
            <div class="select-bottom">
              <el-select v-model="editData.classifyParentId" placeholder="请选择" @change="classifyEvent($event)"
                style="width:100%;">
                <el-option v-for="item in classifyParentData" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
              <el-select v-model="editData.classifyId" placeholder="请选择" style="width:100%;padding-top:10px;">
                <el-option v-for="item in classifyChData" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div class="lot-info-j">
        <span>上传拍品图片</span>
        <span style="color: #D10000;font-size:12px;padding-left:5px">图片像素：高或宽大于500px；文件大小：3M以下</span>
      </div>
      <ImgUpload v-model="imgList" />
      <!-- <example @imageList='imageList' /> -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addAuctionItem('editData')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import {
    attribute,
    addAuctionItem,
    classify
  } from '../../../../../api/index'
  // import example from '../../../../../components/example/index.vue'
  import ImgUpload from '../../../../../components/ImgUpload/index.vue'
  import utils from '../..../../../../../../utils/utils'
  export default {
    name: 'PreviewLotListDiaLog',
    components: {
      // example
      ImgUpload
    },
    data() {
      var checkAge = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('请输入起拍价'))
        } else {
          callback()
        }
      }
      return {
        dialogVisible: false,
        options: [],
        attributeOne: [],
        attributeTwo: [],
        attributeSthree: [],
        // value: '',
        attributeData: {
          authorization: localStorage.getItem('accessToken'),
          userId: Number(localStorage.getItem('accessId')),
          id: 0
        },
        addAuctionItemData: {
          x_user_id: Number(localStorage.getItem('accessId')),
          authorization: localStorage.getItem('accessToken')
        },
        classifyData: {
          authorization: localStorage.getItem('accessToken'),
          id: 0,
          x_user_id: Number(localStorage.getItem('accessId'))
        },
        classifyChData: [],
        classifyParentData: [],
        limit: 99,
        isUploading: false, // 正在上传状态
        isSingle: true,
        headers: {},
        height: 100,
        width: 100,
        value: [],
        imgList: [],
        isExamine: false, // 拍卖会是否审核通过
        rules: {
          lot: [{
            required: true,
            message: '请输入拍品Lot号',
            trigger: 'change'
          }],
          title: [{
            required: true,
            message: '请输入拍品名称',
            trigger: 'change'
          }],
          itemOverview: [{
            required: true,
            message: '请输入拍品描述',
            trigger: 'change'
          }],
          initialPrice: [{
            validator: checkAge,
            required: true,
            trigger: 'change'
          }],
          highestValuation: [{
            required: true,
            message: '请输入拍品估价',
            trigger: 'change'
          }]
        }
      }
    },
    created() {
      // console.log(this.$route.query.auctionIsverify)
    },
    props: {
      editData: {
        type: Object
      }
    },
    watch: {
      dialogVisible: {
        handler(newV, oldV) {
          if (newV === false) {
            this.$emit('addLot')
          }
        }
      }
    },

    methods: {
      open() {
        // if(this.$route.query.auctionIsverify==1||this.$route.query.auctionIsverify==2||this.$route.query.auctionIsverify==3){
        //   this.isExamine=false
        // }else{
        //   this.isExamine=true
        // }
        this.addAuctionItemData.id = this.editData.id
        if (this.editData.images !== '' && this.editData.images !== undefined) {
          var string = this.editData.images
          if (string !== '') {
            var stringResult = string.split(',')
            this.imgList = stringResult
          }
        } else {
          this.imgList = []
        }

        if (this.editData.brandAuthorId === 0) {
          this.editData.brandAuthorId = ''
        }
        if (this.editData.stylePeriodId === 0) {
          this.editData.stylePeriodId = ''
        }
        if (this.editData.materialsCraftId === 0) {
          this.editData.materialsCraftId = ''
        }
        if (this.editData.classifyParentId === 0) {
          this.editData.classifyParentId = ''
        }
        if (this.editData.classifyId === 0) {
          this.editData.classifyId = ''
        }
        this.dialogVisible = true
        this.attribute()
        this.classify()
        if (this.editData.classifyParentId !== '' && this.editData.classifyParentId !== undefined) {
          this.classifyTwo(this.editData.classifyParentId)
        }
      },
      changeBlur() {
        this.editData.initialPrice = utils.numFormat(this.editData.initialPrice)
        if (this.editData.minimumValuation !== '') {
          this.editData.minimumValuation = utils.numFormat(this.editData.minimumValuation)
        }
        if (this.editData.highestValuation !== '') {
          this.editData.highestValuation = utils.numFormat(this.editData.highestValuation)
        }
      },
      async attribute() {
        const res = await attribute(this.attributeData)
        if (res.code === 0) {
          this.attributeOne = res.data.list[1].valueAttribute
          this.attributeTwo = res.data.list[2].valueAttribute
          this.attributeSthree = res.data.list[3].valueAttribute
        } else {
          this.$message.error(res.msg)
        }
      },
      formatDel(num) { // 去除千分号
        return num.toString().replace(/,/gi, '')
      },
      addAuctionItem(formName) {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            // if (Number(this.editData.initialPrice) > Number(this.editData.highestValuation)) {
            //   this.$message.error('起拍价应小于最高估价')
            //   return
            // }
            // if (Number(this.editData.highestValuation) < Number(this.editData.minimumValuation)) {
            //   this.$message.error('拍品最高估价应大于最低估价')
            //   return
            // }
            // if (Number(this.editData.initialPrice) > Number(this.editData.minimumValuation)) {
            //   this.$message.error('拍品起拍价应小于最低估价')
            //   return
            // }
            // if (Number(this.editData.highestValuation) === Number(this.editData.minimumValuation)) {
            //   this.$message.error('最低估价不能等于最高估价')
            //   return
            // }
            this.editData.hostImage = this.imgList.toString()
            this.editData.images = this.imgList.toString()
            this.editData.initialPrice = this.editData.initialPrice.toString()
            this.editData.minimumValuation = this.formatDel(this.editData.minimumValuation)
            this.editData.highestValuation = this.formatDel(this.editData.highestValuation)
            const edit = Object.assign(this.editData, this.addAuctionItemData)
            if (this.editData.brandAuthorId === '') {
              this.editData.brandAuthorId = 0
            }
            if (this.editData.stylePeriodId === '') {
              this.editData.stylePeriodId = 0
            }
            if (this.editData.materialsCraftId === '') {
              this.editData.materialsCraftId = 0
            }
            if (this.editData.classifyParentId === '') {
              this.editData.classifyParentId = 0
            }
            if (this.editData.classifyId === '') {
              this.editData.classifyId = 0
            }
            const res = await addAuctionItem(edit)
            if (res.code === 0) {
              this.$message.success('编辑成功')
              this.dialogVisible = false
            } else {
              this.$message.error(res.msg)
            }
          } else {
            return false
          }
        })
      },
      async classify() {
        this.classifyData.id = 0
        const res = await classify(this.classifyData)
        if (res.code === 0) {
          this.classifyParentData = res.data.list
        } else {
          this.$message.error(res.msg)
        }
      },
      async classifyTwo(event) {
        this.classifyData.id = event

        const res = await classify(this.classifyData)
        if (res.code === 0) {
          this.classifyChData = res.data.list
        } else {
          this.$message.error(res.msg)
        }
      },
      classifyEvent(event) {
        this.editData.classifyId = ''
        this.classifyTwo(event)
      },
      imageList(data) {
        this.addAuctionItemData.hostImage = data
      }

    }
  }
</script>
<style lang="less" scoped>
  .view-preview-lot-list-dia-log {
    .lot-info-j {
      background: #f9f9f9;
      height: 40px;
      line-height: 40px;
      text-align: left;
      font-size: 18px;
      color: #333;
      padding-left: 20px;
    }

    /deep/ .el-dialog__wrapper {
      z-index: 200 !important;
    }

    /deep/ .el-dialog__footer {
      text-align: center;
    }

    /deep/ .el-button {
      width: 320px;
      height: 44px;
      background: #f5edde;
      border-radius: 8px;
      border: none;
      font-size: 18px;
      font-weight: 600;
      color: #6e5121;
    }

    .lot-info-z {
      margin-top: 20px;

      /deep/ .el-form-item__label {
        font-size: 18px;
        color: #333;
        font-weight: 400;
      }

      /deep/ .el-form-item__content {
        text-align: left;
      }

      // /deep/ .el-form-item__content:last-child {
      //   display: flex;
      //   flex-direction: column;
      // }
      // /deep/ .el-select:last-child {
      //   margin-bottom: 20px;
      // }
      // .select-bottom {
      //   width: 100%;
      //   margin-top: 20px;
      // }
    }

    /deep/ .el-upload {
      width: 100%;
      height: 100%;
    }

    .uploadWrapper {
      margin-top: 20px;
    }

    // 上传按钮
    .uploadIcon {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px dashed #c0ccda;
      background-color: #fbfdff;
      border-radius: 6px;
      font-size: 20px;
      color: #999;

      .limitTxt,
      .uploading {
        position: absolute;
        bottom: 10%;
        left: 0;
        width: 100%;
        font-size: 14px;
        text-align: center;
      }
    }

    // 拖拽
    .vue-draggable {
      display: flex;
      flex-wrap: wrap;

      .draggable-item {
        margin-right: 5px;
        margin-bottom: 5px;
        border: 1px solid #ddd;
        border-radius: 6px;
        position: relative;
        overflow: hidden;

        .el-image {
          width: 100%;
          height: 100%;
        }

        .shadow {
          position: absolute;
          top: 0;
          right: 0;
          background-color: rgba(0, 0, 0, 0.5);
          opacity: 0;
          transition: opacity 0.3s;
          color: #fff;
          font-size: 20px;
          line-height: 20px;
          padding: 2px;
          cursor: pointer;
        }

        &:hover {
          .shadow {
            opacity: 1;
          }
        }
      }

      &.hideShadow {
        .shadow {
          display: none;
        }
      }

      &.single {
        overflow: hidden;
        position: relative;

        .draggable-item {
          position: absolute;
          left: 0;
          top: 0;
          z-index: 1;
        }
      }

      &.maxHidden {
        .uploadBox {
          display: none;
        }
      }
    }
  }
</style>
