<template>
  <div class="view-preview-lot">
    <div class="preview-lot-title">
      <div class="preview-lot-title-left">
        <p class="c-w">{{auctionName}}</p>
        <p class="c-2">{{startTime}}</p>
      </div>
      <div class="preview-lot-title-right">
        <a href="javascript:;" class="title-right" :class="{active:toggleNum===item.id}"
          v-for="(item,index) in previewList" :key="index" @click="toggle(item.id)">{{item.name}}</a>
      </div>
    </div>
    <!-- 拍品列表 -->
    <preview-lot-list v-if="toggleNum===1" @liveAuctionItemListTitle='liveAuctionItemListTitle' />
    <!-- 拍卖会信息 -->
    <auction-information v-if="toggleNum===2" />
    <!-- 成交信息 -->
    <transaction-information v-if="toggleNum===3" />
  </div>
</template>
<script>
import PreviewLotList from './components/Previewlotlist'
import AuctionInformation from './components/Auctioninformation'
import TransactionInformation from './components/Transactioninformation'
export default {
  name: 'PreviewLot',
  components: {
    PreviewLotList,
    AuctionInformation,
    TransactionInformation
  },
  data () {
    return {
      previewList: [
        {
          id: 1,
          name: '拍品列表'
        },
        {
          id: 2,
          name: '拍卖会信息'
        },
        {
          id: 3,
          name: '成交信息'
        }
      ],
      toggleNum: 1,
      auctionName: '',
      startTime: ''
    }
  },
  methods: {
    toggle (id) {
      this.toggleNum = id
    },
    liveAuctionItemListTitle (data) {
      this.auctionName = data.auctionName
      this.startTime = data.startTime
    }
  }
}
</script>
<style lang="less" scoped>
.view-preview-lot {
  background: #f9f9f9;
  padding: 40px 90px 40px 90px;
  .preview-lot-title {
    background: #fff;
    padding: 20px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .preview-lot-title-left {
      text-align: left;
      .c-w {
        font-size: 20px;
        font-weight: 500;
        color: #333;
      }
      .c-2 {
        font-size: 16px;
        color: #333;
        margin-bottom: 0;
      }
    }
    .preview-lot-title-right {
      display: flex;
      .title-right {
        width: 120px;
        height: 44px;
        border-radius: 8px;
        border: 1px solid #cda156;
        line-height: 44px;
        font-size: 16px;
        color: #666666;
        margin-right: 30px;
        &:hover {
          color: #cda156;
          background: #f5edde;
        }
      }
      .active {
        color: #cda156;
        background: #f5edde;
      }
      .title-right:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
