<template>
  <div class="view-preview-lot-list-dia-log">
    <el-dialog title="新增单个拍品信息" :visible.sync="dialogVisible" width="40%">
      <div class="lot-info-j">拍品基本信息</div>
      <div class="lot-info-z">
        <el-form ref="addAuctionItemData" :model="addAuctionItemData" :rules="rules" label-width="110px"
          :validate-on-rule-change='false'>
          <el-form-item label="拍品Lot号" prop="lot">
            <el-input v-model="addAuctionItemData.lot" placeholder="请输入Lot号"></el-input>
          </el-form-item>
          <el-form-item label="拍品名称" prop="title">
            <el-input v-model="addAuctionItemData.title" placeholder="请输入拍品名称"></el-input>
          </el-form-item>
          <el-form-item label="拍品描述" prop="itemOverview">
            <el-input v-model="addAuctionItemData.itemOverview" placeholder="请输入拍品描述 "></el-input>
          </el-form-item>
          <el-form-item label="起拍价" prop="initialPrice">
            <el-input v-model.number="addAuctionItemData.initialPrice" @blur="changeBlur()" placeholder="请输入起拍价">
            </el-input>
          </el-form-item>
          <el-form-item label="拍品估价" prop="highestValuation">
            <el-input v-model.number="addAuctionItemData.minimumValuation" @blur="changeBlur()" style="width:160px"
              placeholder="最低估价">
            </el-input>
            <span style="padding:0 20px">————</span>
            <el-input v-model.number="addAuctionItemData.highestValuation" @blur="changeBlur()" style="width:160px"
              placeholder="最高估价">
            </el-input>
          </el-form-item>
          <el-form-item label="品牌/作者">
            <div class="select-bottom">
              <el-select v-model="addAuctionItemData.brandAuthorId" placeholder="请选择" style="width:100%">
                <el-option v-for="item in attributeOne" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item label="风格/时期">
            <div class="select-bottom">
              <el-select v-model="addAuctionItemData.stylePeriodId" placeholder="请选择" style="width:100%">
                <el-option v-for="item in attributeTwo" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item label="材料/工艺">
            <div class="select-bottom">
              <el-select v-model="addAuctionItemData.materialsCraftId" placeholder="请选择" style="width:100%">
                <el-option v-for="item in attributeSthree" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item label="拍品分类">
            <div class="select-bottom">
              <el-select v-model="addAuctionItemData.classifyParentId" placeholder="请选择" @change="classifyEvent($event)"
                style="width:100%;">
                <el-option v-for="item in classifyParentData" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
              <el-select v-model="addAuctionItemData.classifyId" placeholder="请选择" style="width:100%;padding-top:10px;">
                <el-option v-for="item in classifyChData" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div class="lot-info-j">
        <span>上传拍品图片</span>
        <span style="color: #D10000;font-size:12px;padding-left:5px">图片像素：高或宽大于500px；文件大小：3M以下</span>
      </div>
      <!-- <example @imageList='imageList' /> -->
      <ImgUpload v-model="imgList" />

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addAuctionItem('addAuctionItemData')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import {
    attribute,
    addAuctionItem,
    classify
  } from '../../../../../api/index'
  // import example from '../../../../../components/example/index.vue'
  import ImgUpload from '../../../../../components/ImgUpload/index.vue'
  import utils from '../..../../../../../../utils/utils'
  export default {
    name: 'PreviewLotListDiaLog',
    components: {
      // example
      ImgUpload
    },
    data() {
      var checkAge = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('请输入起拍价'))
        } else {
          callback()
        }
      }
      return {
        dialogVisible: false,
        options: [],
        attributeOne: [],
        attributeTwo: [],
        attributeSthree: [],
        // value: '',
        attributeData: {
          authorization: localStorage.getItem('accessToken'),
          userId: Number(localStorage.getItem('accessId')),
          id: 0
        },
        addAuctionItemData: {
          id: 0,
          auctionId: Number(this.$route.query.eventsId),
          title: '',
          lot: '',
          initialPrice: '',
          hostImage: '',
          itemOverview: '',
          itemAuctionStatus: 0,
          brandAuthorId: '', // 品牌作者
          stylePeriodId: '', // 风格
          materialsCraftId: '', // 材料
          classifyParentId: '',
          classifyId: '',
          minimumValuation: '',
          highestValuation: '',
          x_user_id: Number(localStorage.getItem('accessId')),
          authorization: localStorage.getItem('accessToken')
        },
        classifyData: {
          authorization: localStorage.getItem('accessToken'),
          id: 0,
          x_user_id: Number(localStorage.getItem('accessId'))
        },
        classifyChData: [],
        classifyParentData: [],
        limit: 99,
        isUploading: false, // 正在上传状态
        isSingle: true,
        headers: {},
        height: 100,
        width: 100,
        value: [],
        imgList: [],
        rules: {
          lot: [{
            required: true,
            message: '请输入拍品Lot号',
            trigger: 'change'
          }],
          title: [{
            required: true,
            message: '请输入拍品名称',
            trigger: 'change'
          }],
          itemOverview: [{
            required: true,
            message: '请输入拍品描述',
            trigger: 'change'
          }],
          initialPrice: [{
            validator: checkAge,
            required: true,
            trigger: 'change'
          }],
          highestValuation: [{
            required: true,
            message: '请输入拍品估价',
            trigger: 'change'
          }]
        }
      }
    },
    created() {},
    watch: {
      classifyData: {
        deep: true,
        handler(newV, oldV) {
          if (newV === oldV) {
            this.addAuctionItemData.classifyId = ''
          }
        }
      }
    },
    methods: {
      open() {
        this.dialogVisible = true
        this.addAuctionItemData.title = ''
        this.addAuctionItemData.lot = ''
        this.addAuctionItemData.initialPrice = ''
        this.addAuctionItemData.hostImage = ''
        this.addAuctionItemData.itemOverview = ''
        this.addAuctionItemData.itemAuctionStatus = ''
        this.addAuctionItemData.brandAuthorId = ''
        this.addAuctionItemData.stylePeriodId = ''
        this.addAuctionItemData.materialsCraftId = ''
        this.addAuctionItemData.classifyParentId = ''
        this.addAuctionItemData.classifyId = ''
        this.addAuctionItemData.minimumValuation = ''
        this.addAuctionItemData.highestValuation = ''
        this.imgList = []
        this.$nextTick(() => {
          this.$refs.addAuctionItemData.clearValidate()
        })
        this.attribute()
        this.classify()
      },
      onSubmit() {

      },
      async attribute() {
        const res = await attribute(this.attributeData)
        if (res.code === 0) {
          this.attributeOne = res.data.list[1].valueAttribute
          this.attributeTwo = res.data.list[2].valueAttribute
          this.attributeSthree = res.data.list[3].valueAttribute
        } else {
          this.$message.error(res.msg)
        }
      },
      // 失去焦点加逗号
      changeBlur() {
        this.addAuctionItemData.initialPrice = utils.numFormat(this.addAuctionItemData.initialPrice)
        if (this.addAuctionItemData.minimumValuation !== '') {
          this.addAuctionItemData.minimumValuation = utils.numFormat(this.addAuctionItemData.minimumValuation)
        }
        if (this.addAuctionItemData.highestValuation !== '') {
          this.addAuctionItemData.highestValuation = utils.numFormat(this.addAuctionItemData.highestValuation)
        }
      },

      async addAuctionItem(formName) {
        console.log(this.addAuctionItemData.minimumValuation);
        console.log(this.addAuctionItemData.highestValuation);
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            // if (Number(this.addAuctionItemData.initialPrice) > Number(this.addAuctionItemData.highestValuation)) {
            //   this.$message.error('起拍价应小于最高估价')
            //   return
            // }
            // if (Number(this.addAuctionItemData.highestValuation) < Number(this.addAuctionItemData.minimumValuation)) {
            //   this.$message.error('拍品最高估价应大于最低估价')
            //   return
            // }
            // if (Number(this.addAuctionItemData.initialPrice) > Number(this.addAuctionItemData.minimumValuation)) {
            //   this.$message.error('拍品起拍价应小于最低估价')
            //   return
            // }
            // if (Number(this.addAuctionItemData.highestValuation) === Number(this.addAuctionItemData.minimumValuation)) {
            //   this.$message.error('最低估价不能等于最高估价')
            //   return
            // }
            this.addAuctionItemData.hostImage = this.imgList.toString()
            this.addAuctionItemData.itemAuctionStatus = 1
            this.addAuctionItemData.initialPrice = this.addAuctionItemData.initialPrice.toString()
            // this.addAuctionItemData.minimumValuation = this.addAuctionItemData.minimumValuation.toString()
            // this.addAuctionItemData.highestValuation = this.addAuctionItemData.highestValuation.toString()
            this.addAuctionItemData.minimumValuation = this.formatDel(this.addAuctionItemData.minimumValuation)
            this.addAuctionItemData.highestValuation = this.formatDel(this.addAuctionItemData.highestValuation)
            const newData = JSON.parse(JSON.stringify(this.addAuctionItemData)) // 防止提交失败页面属性框变为0
            if (newData.brandAuthorId === '') {
              newData.brandAuthorId = 0
            }
            if (newData.stylePeriodId === '') {
              newData.stylePeriodId = 0
            }
            if (newData.materialsCraftId === '') {
              newData.materialsCraftId = 0
            }
            if (newData.classifyParentId === '') {
              newData.classifyParentId = 0
            }
            if (newData.classifyId === '') {
              newData.classifyId = 0
            }
            // if (this.addAuctionItemData.brandAuthorId === '') {
            //   this.addAuctionItemData.brandAuthorId = 0
            // }
            // if (this.addAuctionItemData.stylePeriodId === '') {
            //   this.addAuctionItemData.stylePeriodId = 0
            // }
            // if (this.addAuctionItemData.materialsCraftId === '') {
            //   this.addAuctionItemData.materialsCraftId = 0
            // }
            // if (this.addAuctionItemData.classifyParentId === '') {
            //   this.addAuctionItemData.classifyParentId = 0
            // }
            // if (this.addAuctionItemData.classifyId === '') {
            //   this.addAuctionItemData.classifyId = 0
            // }
            const res = await addAuctionItem(newData)
            if (res.code === 0) {
              this.$message.success('新增成功')
              this.dialogVisible = false
              this.$emit('addLot')
            } else {
              this.$message.error(res.msg)
            }
          } else {
            return false
          }
        })
      },
      formatDel(num) { // 去除千分号
        return num.toString().replace(/,/gi, '')
      },
      async classify() {
        const res = await classify(this.classifyData)
        if (res.code === 0) {
          this.classifyParentData = res.data.list
        } else {
          this.$message.error(res.msg)
        }
      },
      async classifyEvent(event) {
        this.classifyData.id = event
        const res = await classify(this.classifyData)
        if (res.code === 0) {
          this.classifyChData = res.data.list
        } else {
          this.$message.error(res.msg)
        }
      },
      imageList(data) {
        this.addAuctionItemData.hostImage = data
      }

    }
  }
</script>
<style lang="less" scoped>
  .view-preview-lot-list-dia-log {
    /deep/ .el-dialog__footer {
      text-align: center;
    }

    /deep/ .el-button {
      width: 320px;
      height: 44px;
      background: #f5edde;
      border-radius: 8px;
      border: none;
      font-size: 18px;
      font-weight: 600;
      color: #6e5121;
    }

    /deep/ .el-dialog__wrapper {
      z-index: 200 !important;
    }

    .lot-info-j {
      background: #f9f9f9;
      height: 40px;
      line-height: 40px;
      text-align: left;
      font-size: 18px;
      color: #333;
      padding-left: 20px;
      display: flex;
      align-items: center;
    }

    .lot-info-z {
      margin-top: 20px;

      /deep/ .el-form-item__label {
        font-size: 18px;
        color: #333;
        font-weight: 400;
      }

      /deep/ .el-form-item__content {
        text-align: left;
      }

      // /deep/ .el-form-item__content:last-child {
      //   display: flex;
      //   flex-direction: column;
      // }
      // /deep/ .el-select:last-child {
      //   margin-bottom: 20px;
      // }
      // .select-bottom {
      //   width: 100%;
      //   margin-top: 20px;
      // }
    }

    /deep/ .el-upload {
      width: 100%;
      height: 100%;
    }

    .uploadWrapper {
      margin-top: 20px;
    }

    // 上传按钮
    .uploadIcon {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px dashed #c0ccda;
      background-color: #fbfdff;
      border-radius: 6px;
      font-size: 20px;
      color: #999;

      .limitTxt,
      .uploading {
        position: absolute;
        bottom: 10%;
        left: 0;
        width: 100%;
        font-size: 14px;
        text-align: center;
      }
    }

    // 拖拽
    .vue-draggable {
      display: flex;
      flex-wrap: wrap;

      .draggable-item {
        margin-right: 5px;
        margin-bottom: 5px;
        border: 1px solid #ddd;
        border-radius: 6px;
        position: relative;
        overflow: hidden;

        .el-image {
          width: 100%;
          height: 100%;
        }

        .shadow {
          position: absolute;
          top: 0;
          right: 0;
          background-color: rgba(0, 0, 0, 0.5);
          opacity: 0;
          transition: opacity 0.3s;
          color: #fff;
          font-size: 20px;
          line-height: 20px;
          padding: 2px;
          cursor: pointer;
        }

        &:hover {
          .shadow {
            opacity: 1;
          }
        }
      }

      &.hideShadow {
        .shadow {
          display: none;
        }
      }

      &.single {
        overflow: hidden;
        position: relative;

        .draggable-item {
          position: absolute;
          left: 0;
          top: 0;
          z-index: 1;
        }
      }

      &.maxHidden {
        .uploadBox {
          display: none;
        }
      }
    }
  }
</style>
