<!--
 * @Description: 图片上传组件
-->
<template>
  <div class="uploadWrapper">
    <!-- , maxHidden: isMaxHidden  -->
    <vuedraggable class="vue-draggable" :class="{ single: isSingle}" v-model="imgList" tag="ul"
      draggable=".draggable-item" @start="onDragStart" @end="onDragEnd">
      <!-- 拖拽元素 -->
      <li v-for="(item, index) in imgList" :key="item + index" class="draggable-item"
        :style="{ width: width + 'px', height: height + 'px' }">
        <el-image :src="item" :preview-src-list="[item]"></el-image>
        <div class="shadow" @click="onRemoveHandler(index)">
          <i class="el-icon-delete"></i>
        </div>
      </li>
      <!-- 上传按钮 -->
      <!-- :limit="limit" -->
      <el-upload slot="footer" ref="uploadRef" class="uploadBox" :style="{ width: width + 'px', height: height + 'px' }"
        action="" :headers="headers" accept=".jpg,.jpeg,.png,.gif" :show-file-list="false" :multiple="!isSingle"
        :http-request='httpRequest' :on-change="getFileOne" :before-upload="beforeUpload" :on-success="onSuccessUpload">
        <!-- :on-exceed="onExceed" -->
        <i class="el-icon-plus uploadIcon">
          <span class="uploading" v-show="isUploading">正在上传...</span>
          <!-- <span v-if="!isUploading && limit && limit!==15 && !isSingle" class="limitTxt">最多{{ limit }}张</span> -->
        </i>
      </el-upload>
    </vuedraggable>
  </div>
</template>

<script>
import vuedraggable from 'vuedraggable'
import lrz from 'lrz'
import utils from '../../utils/utils'
import OSS from 'ali-oss'
import {
  uploadFile
} from '../../api/index'
export default {
  name: 'ImgUpload',

  props: {
    // 图片数据(图片url组成的数组) 通过v-model传递
    value: {
      type: Array,
      default () {
        return []
      }
    },
    // 限制上传的图片数量
    limit: {
      type: Number,
      default: 15
    },
    // 限制上传图片的文件大小(kb)
    size: {
      type: Number,
      default: 500
    },
    // 是否是单图上传(单图上传就是已传图片和上传按钮重叠)
    isSingle: {
      type: Boolean,
      default: false
    },
    // 是否使用图片压缩
    useCompress: {
      type: Boolean,
      default: false
    },
    // 图片显示的宽度(px)
    width: {
      type: Number,
      default: 100
    },
    // 图片显示的高度(px)
    height: {
      type: Number,
      default: 100
    }
  },

  data () {
    return {
      headers: {},
      isUploading: false, // 正在上传状态
      isFirstMount: true // 控制防止重复回显
    }
  },

  computed: {
    // 图片数组数据
    imgList: {
      get () {
        return this.value
      },
      set (val) {
        if (val.length < this.imgList.length) {
          this.syncElUpload(val)
        }
        this.$emit('input', val)
      }
    }
    // 控制达到最大限制时隐藏上传按钮
    // isMaxHidden () {
    //   return this.imgList.length >= this.limit
    // }
  },

  watch: {
    value: {
      handler (val) {
        if (this.isFirstMount && this.value.length > 0) {
          this.syncElUpload()
        }
      },
      deep: true
    }
  },

  mounted () {
    if (this.value.length > 0) {
      this.syncElUpload()
    }
  },

  methods: {
    // 同步el-upload数据
    syncElUpload (val) {
      const imgList = val || this.imgList
      this.$refs.uploadRef.uploadFiles = imgList.map((v, i) => {
        return {
          name: 'pic' + i,
          url: v,
          status: 'success',
          uid: utils.createUniqueString()
        }
      })
      this.isFirstMount = false
    },
    // 上传图片之前
    beforeUpload (file) {
      this.isFirstMount = false
      if (this.useCompress) {
        // 图片压缩
        return new Promise((resolve, reject) => {
          lrz(file, {
            width: 1920
          }).then((rst) => {
            file = rst.file
          }).always(() => {
            if (utils.validImgUpload(file, this.size)) {
              this.isUploading = true
              resolve()
            } else {
              reject(new Error())
            }
          })
        })
      } else {
        if (utils.validImgUpload(file, this.size)) {
          this.isUploading = true
          return true
        } else {
          return false
        }
      }
    },
    httpRequest () {

    },
    // oss token
    async uploadFile () {
      const res = await uploadFile()
      if (res.code === 0) {
        this.ossToken = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    // 上传拍卖会封面
    async getFileOne (event) {
      const isLt300K = event.raw.size / 1024 / 1024 < 3
      const isTypeTrue = /^image\/(jpeg|png|jpg)$/.test(event.raw.type)
      if (!isLt300K) {
        this.$message.error('上传图片大小不能超过3M!')
        this.fileListOne = []
        return
      }
      if (!isTypeTrue) {
        this.$message.error('上传图片格式不对!')
        this.fileListOne = []
        return
      }
      var uploadHost = 'https://pic.easyebid.com'
      await this.uploadFile()
      var client = new OSS({
        region: 'oss-cn-beijing',
        accessKeyId: this.ossToken.accessKeyId,
        accessKeySecret: this.ossToken.accessKeySecret,
        bucket: 'yideonline',
        stsToken: this.ossToken.securityToken
      })
      var file = event.raw
      const type = file.name.split('.')
      const len = type.length
      const fileName = this.uuid() + '.' + type[len - 1]
      const result = await client.put(`avatar/${fileName}`, file)
      // if (this.imgList.length < this.limit) {
      this.imgList.push(`${uploadHost}/${result.name}`)
      // }
      this.isUploading = false
    },
    // 上传完单张图片
    onSuccessUpload (res, file, fileList) {
      if (res.files) {
        // if (this.imgList.length < this.limit) {
        this.imgList.push(res.files.file)
        // }
      } else {
        this.syncElUpload()
        this.$message({
          type: 'error',
          message: res.msg
        })
      }
      this.isUploading = false
    },
    // 移除单张图片
    onRemoveHandler (index) {
      this.$message.success('删除成功')
      this.imgList = this.imgList.filter((v, i) => {
        return i !== index
      })
    },
    // 超限
    // onExceed () {
    //   this.$refs.uploadRef.abort() // 取消剩余接口请求
    //   this.syncElUpload()
    //   this.$message({
    //     type: 'warning',
    //     message: `图片超限，最多可上传${this.limit}张图片`
    //   })
    // },
    onDragStart (e) {
      e.target.classList.add('hideShadow')
    },
    onDragEnd (e) {
      e.target.classList.remove('hideShadow')
    },
    uuid() {
      let s = [];
      const hexDigits = '0123456789abcdef';
      for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
      }
      s[14] = '4';
      s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
      s[8] = s[13] = s[18] = s[23] = '-';
      return s.join('');
    }
  },

  components: {
    vuedraggable
  }
}
</script>

<style lang="less" scoped>
  /deep/ .el-upload {
    width: 100%;
    height: 100%;
  }

  .uploadWrapper {
    margin-top: 20px;
  }

  // 上传按钮
  .uploadIcon {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed #c0ccda;
    background-color: #fbfdff;
    border-radius: 6px;
    font-size: 20px;
    color: #999;

    .limitTxt,
    .uploading {
      position: absolute;
      bottom: 10%;
      left: 0;
      width: 100%;
      font-size: 14px;
      text-align: center;
    }
  }

  // 拖拽
  .vue-draggable {
    display: flex;
    flex-wrap: wrap;

    .draggable-item {
      margin-right: 5px;
      margin-bottom: 5px;
      border: 1px solid #ddd;
      border-radius: 6px;
      position: relative;
      overflow: hidden;

      .el-image {
        width: 100%;
        height: 100%;
      }

      .shadow {
        position: absolute;
        top: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.5);
        opacity: 0;
        transition: opacity 0.3s;
        color: #fff;
        font-size: 20px;
        line-height: 20px;
        padding: 2px;
        cursor: pointer;
      }

      &:hover {
        .shadow {
          opacity: 1;
        }
      }
    }

    &.hideShadow {
      .shadow {
        display: none;
      }
    }

    &.single {
      overflow: hidden;
      position: relative;

      .draggable-item {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
      }
    }

    &.maxHidden {
      .uploadBox {
        display: none;
      }
    }
  }
</style>
