<template>
  <div class="view-new-auction">
    <div class="new-auction-form-1">
      <el-form :model="auctionInfoData" :rules="rules" ref="auctionInfoData" label-width="170px" class="demo-ruleForm">
        <el-form-item label="拍卖会封面" prop="image">
          <div class="upload-img">
            <el-upload action="" list-type="picture-card" :on-preview="handlePictureCardPreview" multiple :limit="1"
              :on-exceed="handleExceedTwo" :http-request="httpRequest" :on-change="getFileOne" :file-list="fileListOne"
              :class="{ hide: hideUploadEditFour }" :on-remove="removeChange">
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="" />
            </el-dialog>
            <span>上传封面图片文件不能大于2M，格式可以上传jpg、png、jpeg</span>
          </div>
        </el-form-item>
        <el-form-item label="拍卖会名称" prop="title">
          <el-input v-model="auctionInfoData.title" placeholder="请输入拍卖会名称">
          </el-input>
        </el-form-item>
        <el-form-item label="拍卖会类型" prop="auctionWayId">
          <el-select v-model="auctionInfoData.auctionWayId" placeholder="请选择" disabled>
            <el-option v-for="item in auctionWayIdData" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="时区" prop="timeZone">
          <el-select v-model="auctionInfoData.timeZone" placeholder="请选择">
            <el-option v-for="item in auctionTimeZoneListData" :key="item.id" :label="item.utc + item.nameCn"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开拍时间" prop="startTime" style="align-items:normal">
          <el-date-picker v-model="auctionInfoData.startTime" type="datetime" placeholder="选择日期时间" @change="time">
          </el-date-picker>
          <div class="terms">
            <input type="checkbox" v-model="checked" checked />
            <p>按照上一场拍卖会结束时间顺延</p>
          </div>
          <div class="terms">
            <input type="checkbox" v-model="auctionInfoData.deferment" checked />
            <p>延期待定</p>
          </div>
        </el-form-item>
        <el-form-item label="拍卖地址" prop="attrPlaceSourceId">
          <div class="el-dis">
            <div class="el-select-box">
              <el-select v-model="auctionInfoData.attrPlaceSourceId" @change="changeOne" placeholder="请选择">
                <el-option v-for="item in auctionAreaListData" :key="item.code" :label="item.name" :value="item.code">
                </el-option>
              </el-select>
            </div>
            <div class="el-select-box" v-if="selectShow">
              <el-select v-model="countryTwo" @change="changeTwo" placeholder="请选择">
                <el-option v-for="item in auctionAreaListDataTwo" :key="item.code" :label="item.name"
                  :value="{ value: item.code, label: item.name }">
                </el-option>
              </el-select>
            </div>
            <div class="el-select-box" v-if="selectShow">
              <el-select v-model="countryShtree" @change="changeShtree" placeholder="请选择">
                <el-option v-for="item in auctionAreaListSthreeData" :key="item.code" :label="item.name"
                  :value="{ value: item.code, label: item.name }">
                </el-option>
              </el-select>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="详细地址" prop="location">
          <div class="textarea-box">
            <textarea name="" placeholder="请填写您的详细地址" class="textarea" v-model="auctionInfoData.location"></textarea>
          </div>
        </el-form-item>
        <el-form-item label="保证金比例" prop="earnestMoney">
          <el-input v-model="auctionInfoData.earnestMoney" placeholder="请输入保证金比例" :disabled="disShow">
          </el-input>
        </el-form-item>
        <el-form-item label="货币单位" prop="monetaryUnit">
          <el-select v-model="auctionInfoData.monetaryUnit" placeholder="请选择">
            <el-option v-for="item in monetaryUnitData" :key="item.id" :label="item.currency" :value="item.currency">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分类" prop="classifyId">
          <div class="classify-Id">
            <el-checkbox-group v-model="auctionInfoData.classifyId">
              <el-checkbox text-color="#cda156" v-for="item in valueAttribute" :label="item.id" :key="item.id">
                {{ item.name }}
              </el-checkbox>
            </el-checkbox-group>

            <!-- <el-checkbox v-model="auctionInfoData.classifyId" text-color="#cda156" v-for="item in valueAttribute"
              :label="item.id" :key="item.id">
              {{ item.name }}
            </el-checkbox> -->
          </div>
        </el-form-item>
        <el-form-item label="拍卖条款" prop="auctionTerms">
          <div class="textarea-box">
            <textarea name="" placeholder="请填写拍卖条款" class="textarea" v-model="auctionInfoData.auctionTerms"></textarea>
          </div>
        </el-form-item>
        <el-form-item label="付款方式" prop="paymentMethod">
          <div class="textarea-box">
            <textarea name="" placeholder="请填写付款方式" class="textarea" v-model="auctionInfoData.paymentMethod"></textarea>
          </div>
        </el-form-item>
        <el-form-item label="邮寄方式" prop="mailingMethod">
          <div class="textarea-box">
            <textarea name="" placeholder="请填写邮寄方式" class="textarea" v-model="auctionInfoData.mailingMethod"></textarea>
          </div>
        </el-form-item>
        <bidding-ladder v-model="biddingLadderData" />
        <div class="button-okz">
          <button class="go-one" @click.prevent="next('auctionInfoData')">
            保存
          </button>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import OSS from 'ali-oss'
import {
  uploadFile,
  auctionTimeZoneList,
  auctionAreaList,
  exchangeRateList,
  addAuction,
  auctionInfo,
  auctionAttribute
} from '../../../../api/index'
import biddingLadder from '@/components/Biddingladder.vue'
export default {
  name: 'AuctionInformation',
  data () {
    return {
      countryShtree: '',
      countryTwo: '',
      auctionInfoData: {},
      dialogImageUrl: '',
      selectShow: true,
      ossToken: '',
      fileListOne: [],
      dialogVisible: false,
      rules: {
        image: [{
          required: true,
          message: '请上传拍卖会封面',
          trigger: 'change'
        }],
        title: [{
          required: true,
          message: '请输入拍卖会名称',
          trigger: 'change'
        }],
        timeZone: [{
          required: true,
          message: '请选择时区',
          trigger: 'change'
        }],
        startTime: [{
          required: true,
          message: '请选择开拍时间',
          trigger: 'change'
        }],
        attrPlaceSourceId: [{
          required: true,
          message: '请选择拍卖地址',
          trigger: 'change'
        }],
        classifyId: [{
          type: 'array',
          required: true,
          message: '请选择分类',
          trigger: 'change'
        }
          // {  trigger: 'change' }
        ],
        location: [{
          required: true,
          message: '请填写详细地址',
          trigger: 'change'
        }],
        earnestMoney: [{
          required: true,
          message: '请输入保证金比例',
          trigger: 'change'
        }],
        monetaryUnit: [{
          required: true,
          message: '请选择货币单位',
          trigger: 'change'
        }],
        auctionTerms: [{
          required: true,
          message: '请输入拍卖条款',
          trigger: 'change'
        }],
        paymentMethod: [{
          required: true,
          message: '请输入付款方式',
          trigger: 'change'
        }],
        mailingMethod: [{
          required: true,
          message: '请输入邮寄方式',
          trigger: 'change'
        }]
      },
      auctionTimeZoneListData: [],
      checked: false,
      auctionAreaListData: [],
      auctionAreaListDataTwo: [],
      auctionAreaListSthreeData: [],
      countyTxtTwo: '',
      countyTxtStree: '',
      monetaryUnitData: [],
      valueAttribute: [],
      auction: {
        auctionId: 0,
        userId: Number(localStorage.getItem('accessId')),
        x_user_id: Number(localStorage.getItem('accessId')),
        authorization: localStorage.getItem('accessToken'),
        auctionTerm: ''
      },
      auctionWayIdData: [{
        id: 7,
        name: '直播拍卖'
      },

      {
        id: 8,
        name: '委托拍卖'
      }
      ],
      disShow: true,
      hideUploadEditFour: false,
      biddingLadderData: {
        iconPriceList: 1,
        iconList: 1,
        buyersPremium: {
          min: [],
          max: []
        },
        bidding: {
          min: [],
          max: []
        }
      }
    }
  },
  components: {
    biddingLadder
  },
  created () {
    this.auctionTimeZoneList()
    this.auctionAreaList()
    this.exchangeRateList()
    this.auctionInfo()
    this.uploadShow()
    this.auctionAttribute()
    if (
      Number(this.$route.query.auctionIsverify) === 1 ||
        Number(this.$route.query.auctionIsverify) === 2
    ) {
      this.disShow = false
    }
  },

  methods: {
    // 回显数据
    async auctionInfo () {
      const res = await auctionInfo({
        authorization: localStorage.getItem('accessToken'),
        x_user_id: Number(localStorage.getItem('accessId')) || 0,
        auctionId: Number(this.$route.query.eventsId)
      })
      if (res.code === 0) {
        this.fileListOne = []
        this.auctionInfoData = res.data
        const numArray = this.auctionInfoData.classifyId.split(',')
        this.auctionInfoData.classifyId = []
        for (let index = 0; index < numArray.length; index++) {
          this.auctionInfoData.classifyId.push(Number(numArray[index]))
        }
        this.biddingLadderFun()
        this.auctionInfoData.timeZone = Number(this.auctionInfoData.timeZone)
        this.fileListOne.push({
          name: 'image',
          url: this.auctionInfoData.image
        })
        if (this.auctionInfoData.auctionPostpone === 1) {
          this.checked = false
        } else {
          this.checked = true
        }
        if (this.auctionInfoData.attrPlaceSourceId === 0) {
          this.selectShow = true
          this.auctionAreaListTwo('000')
        } else {
          this.selectShow = false
        }
        const areaNameData = this.auctionInfoData.areaName.split(',')
        this.countryTwo = areaNameData[0]
        this.countryShtree = areaNameData[1]
      } else {
        this.$message.error(res.msg)
      }
    },
    // 阶梯数据回显
    biddingLadderFun () {
      this.biddingLadderData.iconPriceList = 1
      this.biddingLadderData.iconList = 1
      this.biddingLadderData.buyersPremium.min = []
      this.biddingLadderData.buyersPremium.max = []
      this.biddingLadderData.bidding.min = []
      this.biddingLadderData.bidding.max = []
      // 佣金设置
      for (const key in this.auctionInfoData.buyersPremium.min) {
        const len = this.biddingLadderData.buyersPremium.min.length
        const lastIndex = Object.keys(this.auctionInfoData.buyersPremium.min).length - 1
        // const burMin = {
        //   index: len + 1,
        //   content: this.auctionInfoData.buyersPremium.min[key] === 9999999999
        //     ? '∞'
        //     : this.format(this.auctionInfoData.buyersPremium.min[key])
        // }
        const burMin = {
          index: len + 1,
          content: Number(key) === lastIndex ? '∞' : this.format(this.auctionInfoData.buyersPremium.min[key])
        }
        this.biddingLadderData.buyersPremium.min.push(burMin)
      }
      for (const key in this.auctionInfoData.buyersPremium.max) {
        const len = this.biddingLadderData.buyersPremium.max.length
        const burMax = {
          index: len + 1,
          content: this.format(this.auctionInfoData.buyersPremium.max[key]),
          checked: false
        }
        this.biddingLadderData.buyersPremium.max.push(burMax)
      }
      // vat
      for (const key in this.auctionInfoData.buyersPremium.vat) {
        this.biddingLadderData.buyersPremium.max[
          key
        ].checked = this.auctionInfoData.buyersPremium.vat[key]
      }
      // 竞价阶梯设置
      // for (const key in this.auctionInfoData.bidding.min) {
      //   const len = this.biddingLadderData.bidding.min.length
      //   const bidMin = {
      //     index: len + 1,
      //     content: this.auctionInfoData.bidding.min[key] === 9999999999
      //       ? '∞'
      //       : this.format(this.auctionInfoData.bidding.min[key])
      //   }
      //   this.biddingLadderData.bidding.min.push(bidMin)
      // }
      // 竞价阶梯设置
      for (const key in this.auctionInfoData.bidding.min) {
        const len = this.biddingLadderData.bidding.min.length
        const lastIndex = Object.keys(this.auctionInfoData.bidding.min).length - 1
        const bidMin = {
          index: len + 1,
          content: Number(key) === lastIndex ? '∞' : this.format(this.auctionInfoData.bidding.min[key])
        }
        this.biddingLadderData.bidding.min.push(bidMin)
      }
      // 竞价阶梯右边
      let index = -1
      const maxArr = []
      for (const key in this.auctionInfoData.bidding.max) {
        this.biddingLadderData.bidding.max.push({
          index: (index += 1),
          data: []
        })
        maxArr.push(this.auctionInfoData.bidding.max[key])
      }
      for (let i = 0; i < maxArr.length; i++) {
        maxArr[i].forEach(item => {
          this.biddingLadderData.bidding.max[i].data.push({
            content: this.format(item),
            iconShow: true
          })
        })
        for (let j = 0; j < this.biddingLadderData.bidding.max.length; j++) {
          for (
            let index = 0; index < this.biddingLadderData.bidding.max[j].data.length; index++
          ) {
            const len = this.biddingLadderData.bidding.max[j].data.length - 1
            if (
              this.biddingLadderData.bidding.max[j].data[len].iconShow === true
            ) {
              this.biddingLadderData.bidding.max[j].data[len].iconShow = false
            }
          }
        }
      }
      this.biddingLadderData.iconPriceList = this.biddingLadderData.buyersPremium.max.length
      this.biddingLadderData.iconList = this.biddingLadderData.bidding.max.length
    },
    // 获取时区
    async auctionTimeZoneList () {
      const res = await auctionTimeZoneList({
        authorization: localStorage.getItem('accessToken'),
        x_user_id: Number(localStorage.getItem('accessId')) || 0
      })
      if (res.code === 0) {
        this.auctionTimeZoneListData = res.data.list
      } else {
        this.$message.error(res.msg)
      }
    },
    // 获取单位
    async exchangeRateList () {
      const res = await exchangeRateList()
      if (res.code === 0) {
        this.monetaryUnitData = res.data.list
      } else {
        this.$message.error(res.msg)
      }
    },
    formatDel (num) {
      // 去除千分号
      const newval = num.toString().replace(/,/gi, '')
      return newval === '∞' ? '∞' : Number(newval)
    },
    format (num) {
      // 千分位
      return (num + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')
    },
    // 保存
    async next (formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          console.log(this.biddingLadderData)
          // for (let index = 0; index < this.biddingLadderData.buyersPremium.min.length; index++) {
          //   if (this.biddingLadderData.buyersPremium.min[index].content === '' || this.biddingLadderData.buyersPremium.min[index].content === '0') {
          //     this.$message.error('佣金设置不能为空或者不能为0')
          //     return
          //   }
          // }
          // for (let index = 0; index < this.biddingLadderData.buyersPremium.max.length; index++) {
          //   if (this.biddingLadderData.buyersPremium.max[index].content === '' || this.biddingLadderData.buyersPremium.max[index].content === null || this.biddingLadderData.buyersPremium.max[index].content === '0') {
          //     this.$message.error('佣金设置不能为空或者不能为0')
          //     return
          //   }
          // }
          // for (let index = 0; index < this.biddingLadderData.bidding.min.length; index++) {
          //   if (this.biddingLadderData.bidding.min[index].content === '' || this.biddingLadderData.bidding.min[index].content === '0') {
          //     this.$message.error('竞价阶梯不能为空或者不能为0')
          //     return
          //   }
          // }
          // for (let index = 0; index < this.biddingLadderData.bidding.max.length; index++) {
          //   // if(this.biddingLadderData.bidding.max[index].data.length==2){
          //   //   return this.$message.error('竞价阶梯只能输入一个框或三个框')
          //   // }
          //   for (let j = 0; j < this.biddingLadderData.bidding.max[index].data.length; j++) {
          //     if (this.biddingLadderData.bidding.max[index].data[j].content === '' || this.biddingLadderData.bidding.max[index].data[j].content === '0' || this.biddingLadderData.bidding.max[index].data[j].content === null) {
          //       this.$message.error('竞价阶梯不能为空或者不能为0')
          //       return
          //     }
          //   }
          // }
          let commissionReg = true
          this.biddingLadderData.buyersPremium.min.slice(1).map(item => {
            item.content = this.formatDel(item.content)
            if (
              item.content === 0 ||
                item.content === '' ||
                item.content === null
            ) {
              commissionReg = false
            }
          })
          this.biddingLadderData.buyersPremium.max.map(item => {
            item.content = this.formatDel(item.content)
            if (item.content === '' || item.content === null) {
              commissionReg = false
            }
          })
          if (!commissionReg) {
            return this.$message.error('佣金设置不能为空且左侧区间不能为0')
          }

          let biddingReg = true
          this.biddingLadderData.bidding.min.slice(1).map(item => {
            item.content = this.formatDel(item.content)
            if (!item.content) {
              biddingReg = false
            }
          })
          this.biddingLadderData.bidding.max.map(item => {
            item.data.map(item2 => {
              item2.content = this.formatDel(item2.content)
              if (!item2.content) {
                biddingReg = false
              }
            })
          })
          if (!biddingReg) {
            return this.$message.error('竞价阶梯不能为空且大于0')
          }
          if (this.checked === true) {
            this.auctionInfoData.auctionPostpone = 2
          } else {
            this.auctionInfoData.auctionPostpone = 1
          }
          this.auctionInfoData.classifyId = this.auctionInfoData.classifyId.toString()
          this.auction.auctionId = this.auctionInfoData.id
          this.auction.auctionTerm = this.auctionInfoData.auctionTerms
          const params = Object.assign(this.auctionInfoData, this.auction)
          this.auctionInfoData.buyersPremium = this.biddingLadderData.buyersPremium
          this.auctionInfoData.bidding = this.biddingLadderData.bidding
          const res = await addAuction(params)
          if (res.code === 0) {
            localStorage.setItem('auctionId', res.data.AuctionId)
            this.auctionInfo()
            this.$message.success('保存成功')
          } else {
            this.$message.error(res.msg)
          }
        } else {
          return false
        }
      })
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    httpRequest () {},
    handleExceedTwo (files, fileList) {
      this.$message.warning('当前限制上传 1 个文件')
    },
    random_string (len) {
      len = len || 32
      var chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
      var maxPos = chars.length
      var pwd = ''
      for (let i = 0; i < len; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos))
      }
      return pwd
    },
    // oss token
    async uploadFile () {
      const res = await uploadFile()
      if (res.code === 0) {
        this.ossToken = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    // 上传拍卖会封面
    async getFileOne (event) {
      const isLt300K = event.raw.size / 1024 / 1024 < 2
      const isTypeTrue = /^image\/(jpeg|png|jpg)$/.test(event.raw.type)
      if (!isLt300K) {
        this.$message.error('上传图片大小不能超过2M!')
        this.fileListOne = []
        return
      }
      if (!isTypeTrue) {
        this.$message.error('上传图片格式不对!')
        this.fileListOne = []
        return
      }
      var uploadHost = 'https://pic.easyebid.com'
      await this.uploadFile()
      var client = new OSS({
        region: 'oss-cn-beijing',
        accessKeyId: this.ossToken.accessKeyId,
        accessKeySecret: this.ossToken.accessKeySecret,
        bucket: 'yideonline',
        stsToken: this.ossToken.securityToken
      })
      var file = event.raw
      const type = file.name.split('.')
      var timestamp = Date.parse(new Date())
      const len = type.length
      const fileName = 'yidefm' + timestamp + '.' + type[len - 1]
      const result = await client.put(`avatar/${fileName}`, file)
      this.auctionInfoData.image = `${uploadHost}/${result.name}`
      this.$refs.auctionInfoData.validateField('image')

      this.uploadShow()
    },
    removeChange (file) {
      this.auctionInfoData.image = ''
      this.$refs.auctionInfoData.validateField('image')
      this.hideUploadEditFour = false
    },
    uploadShow () {
      if (this.auctionInfoData.image !== '') {
        this.hideUploadEditFour = true
      }
    },
    // 获取所属国家
    async auctionAreaList () {
      const res = await auctionAreaList({
        code: ''
      })
      if (res.code === 0) {
        this.auctionAreaListData = res.data.newList
      } else {
        this.$message.error(res.msg)
      }
    },
    async auctionAreaListTwo (id) {
      if (id === 0) {
        id = '000'
      }
      const res = await auctionAreaList({
        code: id
      })
      if (res.code === 0) {
        this.auctionAreaListDataTwo = res.data.list
      } else {
        this.$message.error(res.msg)
      }
    },
    async auctionAreaListSthree (id) {
      const res = await auctionAreaList({
        code: id.toString()
      })
      if (res.code === 0) {
        this.auctionAreaListSthreeData = res.data.list
      } else {
        this.$message.error(res.msg)
      }
    },
    // 选择国家
    changeOne (params) {
      const {
        value,
        label
      } = params
      this.countyTxtCode = value
      if (params !== 0) {
        this.selectShow = false
      } else {
        this.selectShow = true
        this.auctionAreaListTwo(params)
        this.countyTxt = label
      }
    },
    changeTwo (params) {
      const {
        value,
        label
      } = params
      if (this.selectShow === true) {
        this.auctionAreaListSthree(value)
        this.countyTxtTwo = label
      }
    },
    changeShtree (params) {
      const {
        value,
        label
      } = params
      this.countyTxtStree = label
      this.auctionInfoData.areaName =
          this.countyTxtTwo + ',' + this.countyTxtStree
    },
    // 开拍时间
    time (date) {
      const Y = date.getFullYear()
      const M =
          date.getMonth() + 1 - 0 >= 10
            ? Number(date.getMonth()) + 1
            : '0' + (Number(date.getMonth()) + 1)
      const D = date.getDate() >= 10 ? date.getDate() : '0' + date.getDate()
      const h = date.getHours() >= 10 ? date.getHours() : '0' + date.getHours()
      const m =
          date.getMinutes() >= 10 ? date.getMinutes() : '0' + date.getMinutes()
      const s =
          date.getSeconds() >= 10 ? date.getSeconds() : '0' + date.getSeconds()
      this.auctionInfoData.startTime =
          Y + '-' + M + '-' + D + ' ' + h + ':' + m + ':' + s
    },
    async auctionAttribute () {
      const res = await auctionAttribute({
        id: 0,
        userid: Number(localStorage.getItem('accessId')) || 0,
        x_user_id: Number(localStorage.getItem('accessId')) || 0,
        authorization: localStorage.getItem('accessToken') || 0,
        auctionWayId: '',
        brandAuthorId: '',
        stylePeriodId: '',
        materialsCraftId: '',
        placeSourceId: '',
        auctionHouseId: '',
        time: '',
        page: 1,
        pageSize: 40,
        title: '',
        classifyId: '',
        twoClassifyId: '',
        auctionType: 0
      })
      if (res.code === 0) {
        this.valueAttribute = res.data.list[0].valueAttribute
      } else {
        this.$message.error(res.msg)
      }
    }
  }
}
</script>
<style lang="less" scoped>
  .view-new-auction {
    background: #fff;
    padding-top: 30px;
    padding-bottom: 20px;

    /deep/ .hide .el-upload--picture-card {
      display: none;
    }

    /deep/ .el-checkbox__input.is-checked+.el-checkbox__label {
      color: #666666;
    }

    /deep/ .el-checkbox__label {
      color: #666666;
    }

    /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      background-color: #cda156;
      border-color: #cda156;
    }

    .new-auction-form-1 {
      padding-left: 500px;

      /deep/ .el-upload-list--picture-card .el-upload-list__item {
        width: 120px;
        height: 80px;
      }

      .upload-img {
        display: flex;
        align-items: center;

        span {
          padding-left: 20px;
        }

        /deep/ .el-upload--picture-card {
          width: 120px;
          height: 80px;
          background: #fff;
          border: 1px solid #bfc3c4;
          line-height: 80px;
        }

        /deep/ .el-icon-plus {
          background: url('../../../../assets/images/shangchuantupian备份 2@2x.png') no-repeat;
          background-size: 27px;
        }

        /deep/ .el-upload--picture-card i {
          color: transparent;
        }
      }

      /deep/ .el-form-item {
        display: flex;
        align-items: center;
        // width: 730px;
      }

      /deep/ .el-form-item__content {
        margin-left: 0 !important;
      }

      /deep/ .el-form-item__label {
        font-weight: 600;
        color: rgb(56, 55, 55);
        font-size: 16px;
      }

      /deep/ .el-input__inner {
        width: 600px;
      }

      .classify-Id {
        width: 450px;

        // display: flex;
        // justify-content: space-between;
        // flex-flow: wrap;
        /deep/.el-checkbox-group {
          text-align: left;
          display: flex;
          justify-content: space-between;
          flex-flow: wrap;
        }
      }

      .el-dis {
        display: flex;
        justify-content: space-between;
        width: 600px;

        .el-select-box {
          width: 195px;

          /deep/ .el-input__inner {
            width: 195px;
          }
        }
      }

      .textarea-box {
        width: 600px;

        .textarea {
          width: 100%;
          height: 100px;
          resize: none;
          outline: none;
          border: 1px solid #bfc3c4;
          padding-left: 13px;
        }
      }

      .terms {
        text-align: left;
        display: flex;
        align-items: center;
        // input[type="checkbox"] {
        //   cursor: pointer;
        //   position: relative;
        //   width: 15px;
        //   height: 15px;
        //   font-size: 14px;
        // }

        // input[type="checkbox"]::after {
        //   position: absolute;
        //   top: 0;
        //   color: #000;
        //   width: 15px;
        //   height: 15px;
        //   display: inline-block;
        //   visibility: visible;
        //   padding-left: 0px;
        //   text-align: center;
        //   content: " ";
        //   border-radius: 3px;
        // }

        // input[type="checkbox"]:checked::after {
        //   content: "✓";
        //   color: #fff;
        //   font-size: 12px;
        //   font-weight: bold;
        //   background-color: #cda156;
        // }
        a {
          color: #cda156;
        }

        p {
          font-weight: 400;
          color: #666;
          font-size: 12px;
          margin-bottom: 0;
          margin-left: 6px;
        }
      }

      .rightc {
        width: 800px;
        overflow: hidden;
        line-height: 41px;

        .pricerange2 {
          width: 800px;
          clear: both;
          overflow: hidden;
          text-align: left;

          .leftcp {
            width: 124px;
            float: left;
            overflow: hidden;

            h2 {
              font-size: 16px;
              line-height: 20px;
              text-align: center;
              padding: 5px 0;
              background: #f2f2f2;
              color: #999;
            }

            .cell {
              margin-top: 20px;
              text-align: center;
              height: 40px;

              .texts {
                height: 38px;
                width: 122px;
                border: 1px solid #ccc;
                background: #fff;
                text-align: center;
                font: normal 16px/38px Gotham, 'Helvetica Neue', Helvetica, Arial,
                  sans-serif;
                margin: 0;
                padding: 0;
                border-radius: 5px;
              }

              .infinite {
                background: url('../../../../assets/images/icon_wx.png') no-repeat center center;
              }
            }

            .oper {
              clear: both;
              overflow: hidden;

              a {
                text-decoration: none;
                background-color: transparent;
                color: #666;
                font-family: Arial, Helvetica, '微软雅黑', 'Microsoft YaHei';
              }
            }
          }

          .rightcp {
            width: max-content;
            float: left;

            h2 {
              font-size: 16px;
              line-height: 20px;
              padding: 5px 0 5px 75px;
              text-align: left;
              margin-bottom: 60px;
              background: #f2f2f2;
              color: #999;
              width: 480px;
            }

            // .cell-2 {
            //     margin-bottom: 20px !important;
            // }
            .cell {
              text-align: right;
              margin-bottom: 20px;
              overflow: hidden;
              padding-left: 80px;
              clear: both;
              // width: 596px;
              height: 40px;
              background: url('../../../../assets/images/arrow.png') no-repeat 20px top;
              display: flex;
              align-items: center;

              .texts {
                height: 38px;
                width: 122px;
                border: 1px solid #ccc;
                background: #fff;
                text-align: center;
                font: normal 16px/38px Gotham, 'Helvetica Neue', Helvetica, Arial,
                  sans-serif;
                margin: 0;
                padding: 0;
                border-radius: 5px;
                float: left;
              }

              span {
                float: left;
                text-align: left;
                height: 38px;
                line-height: 38px;
                margin-left: 8px;
              }

              .add {
                width: 21px;
                height: 21px;
                background: url('../../../../assets/images/icon_add.png') no-repeat;
                overflow: hidden;
                float: left;
                margin: 0 0 0 10px;
              }

              .ad-ip {
                display: inline-block;

                .line {
                  width: 20px;
                  text-align: center;
                  color: #ccc;
                  line-height: 34px;
                  float: left;
                }
              }

              .reduce {
                width: 21px;
                height: 21px;
                background: url('../../../../assets/images/icon_-.png');
                overflow: hidden;
                // float: left;
                margin: 10px;
                display: inline-block;
              }
            }
          }
        }
      }
    }

    .button-okz {
      padding-left: 300px;
      display: flex;
      flex-direction: column;

      .go-one {
        width: 280px;
        color: #6e5121;
        font-size: 18px;
        opacity: 0.7;
        border-radius: 8px;
        background: #cda156;
        height: 44px;
        // text-align: center;
        line-height: 44px;
        border: none;
        margin-top: 40px;
        margin-bottom: 10px;
      }
    }
  }
</style>
