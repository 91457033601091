<template>
  <div class="view-transaction-information">
    <div class="search-list">
      <div class="search-list-1">
        <input type="text" class="inp-lot" placeholder="请输入Lot号搜索" v-model="tradeInformationListData.lot">
        <button class="search-data" @click="searchLot()">搜索</button>
      </div>
      <div class="search-list-2">
        <span class="page-show">每页显示</span>
        <el-select v-model="value" placeholder="请选择" @change="changePage">
          <el-option v-for="item in options" :key="item.id" :label="item.num" :value="item.num">
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="information-middle" v-for="(item,index) in tradeInformationListResList" :key="index">
      <div class="information-middle-top" v-if="item.tradeStatus === '2'">
        <span class="f-w-6">成交确认编号：{{item.tradeNo}}</span>
        <span>创建时间：{{item.sendTime}}</span>
      </div>
      <div class="information-middle-top" v-if="item.tradeStatus === '1'">
        <span class="red-h-r">成交待确认</span>
      </div>
      <div class="information-middle-ok">
        <div class="text-ok">
          <p>买家姓名：{{item.realName}}</p>
          <p>联系电话：{{item.mobile}}</p>
        </div>
        <a href="javascript:;" class="look-ok" v-if="item.tradeStatus === '2'"
          @click="confirmationLetterLook(item.outTradeNo,item.userId)">查看成交确认书</a>
        <a href="javascript:;" class="look-ok sta-2" v-if="item.tradeStatus === '1'"
          @click="confirmationLetter(item.outTradeNo,item.auctionId,item.userId)">创建成交确认书</a>
      </div>
      <div class="information-middle-bottom">
        <el-table :data="item.list" style="width: 100%">
          <el-table-column label="Lot号">
            <template slot-scope="scope">
              <span>{{scope.row.lot}}</span>
            </template>
          </el-table-column>
          <el-table-column label="图片">
            <template slot-scope="scope">
              <el-image class="table-td-thumb" :src="scope.row.hostImage" :preview-src-list="[scope.row.hostImage]">
              </el-image>
            </template>
          </el-table-column>
          <el-table-column prop="title" label="拍品名称">
          </el-table-column>
          <el-table-column label="落槌价">
            <template slot-scope="scope">
              <span>{{scope.row.dropHammerPrice}}{{scope.row.currency}}</span>
            </template>
          </el-table-column>
          <el-table-column label="买家佣金">
            <template slot-scope="scope">
              <span>{{scope.row.buyersPremium}}{{scope.row.currency}}</span>
            </template>
          </el-table-column>
          <el-table-column label="成交价">
            <template slot-scope="scope">
              <span>{{scope.row.total}}{{scope.row.currency}}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <deal-dialog ref="dealDialog" :confirmationLetterId='confirmationLetterId' @sendOk='sendOk' />
  </div>
</template>
<script>
import DealDialog from './Dialog/Dealdialog'
import { tradeInformationList } from '../../../../api/index'
export default {
  name: 'TransactionInformation',
  components: {
    DealDialog
  },
  data () {
    return {
      options: [
        {
          id: 1,
          num: 10
        },
        {
          id: 2,
          num: 20
        },
        {
          id: 3,
          num: 25
        },
        {
          id: 4,
          num: 50
        }
      ],
      value: 10,
      newArry: [],
      mockData: [
        {
          status: 1
        },
        {
          status: 2
        }
      ],
      confirmationLetterId: {
        trandNo: '',
        name: '',
        auctionId: 0,
        userId: 0
      },
      tradeInformationListData: {
        x_user_id: Number(localStorage.getItem('accessId')),
        authorization: localStorage.getItem('accessToken'),
        auctionId: Number(this.$route.query.eventsId),
        userId: Number(localStorage.getItem('accessId')),
        page: 1,
        pageSize: 10,
        lot: ''
      },
      tradeInformationListResList: {}
    }
  },
  created () {
    this.tradeInformationList()
  },
  watch: {
    tradeInformationListData: {
      deep: true,
      handler (newV, oldV) {
        if (newV.lot === '') {
          this.tradeInformationList()
        }
      }
    }
  },
  methods: {
    // 数据初始化
    async tradeInformationList () {
      const res = await tradeInformationList(this.tradeInformationListData)
      if (res.code === 0) {
        this.tradeInformationListResList = res.data.list
      } else {
        this.$message.error(res.msg)
      }
    },
    sendOk () {
      this.tradeInformationList()
    },
    // 每页显示
    changePage (page) {
      this.tradeInformationListData.page = 1
      this.tradeInformationListData.pageSize = page
    },
    // 搜索
    searchLot () {
      this.tradeInformationList()
    },
    // 创建/成交确认书
    confirmationLetter (index, id, userId) {
      this.$nextTick(() => {
        this.$refs.dealDialog.open()
      })
      this.confirmationLetterId.trandNo = index
      this.confirmationLetterId.name = '创建'
      this.confirmationLetterId.auctionId = id
      this.confirmationLetterId.userId = userId
    },
    // 查看成交确认书
    confirmationLetterLook (outTradeNo, userId) {
      this.$nextTick(() => {
        this.$refs.dealDialog.open()
      })
      this.confirmationLetterId.name = '查看'
      this.confirmationLetterId.trandNo = outTradeNo
      this.confirmationLetterId.userId = userId
    }
  }
}
</script>
<style lang="less" scoped>
.view-transaction-information {
  background: #fff;
  padding: 20px 40px;
  .search-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .search-list-1 {
      display: flex;
      align-items: center;
      .inp-lot {
        outline: none;
        width: 214px;
        height: 40px;
        background: #fff;
        border-radius: 8px;
        border: 1px solid #bdc3c7;
        font-size: 14px;
        color: #999;
        padding-left: 46px;
        background: url("../../../../assets/images/sousuo@2x.png") no-repeat;
        background-size: 16px;
        background-position-x: 21px;
        background-position-y: 10px;
      }
      .search-data {
        width: 80px;
        height: 40px;
        background: #cda156;
        border-radius: 8px;
        text-align: center;
        line-height: 40px;
        color: #fff;
        border: none;
        font-size: 16px;
        margin-left: 10px;
      }
    }
    .search-list-2 {
      .page-show {
        font-size: 18px;
        color: #333;
        font-weight: 400;
        padding-right: 20px;
      }
      .el-input__inner {
        width: 120px;
        height: 40px;
        border-radius: 0;
        border: 1px solid #bec3c6;
      }
    }
  }
  .information-middle {
    border-radius: 8px;
    border: 1px solid #dedede;
    margin-top: 20px;
    .information-middle-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 20px;
      border-bottom: 1px solid #dedede;
      span {
        color: #333;
        font-weight: 400;
        font-size: 16px;
      }
      .f-w-6 {
        font-size: 20px;
        font-weight: 600;
      }
      .red-h-r {
        font-size: 20px;
        color: #d10000;
        font-weight: 400;
      }
    }
    .information-middle-ok {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      .text-ok {
        text-align: left;
        p {
          color: #333;
          font-size: 16px;
        }
        p:last-child {
          margin-bottom: 0;
        }
      }
      .look-ok {
        padding: 11px 24px;
        background: #cda156;
        border-radius: 8px;
        color: #fff;
        font-size: 16px;
      }
      .sta-2 {
        background: #fff;
        border: 1px solid #cda156;
        color: #cda156;
      }
    }
    .information-middle-bottom {
      padding: 0 20px;
      /deep/ .el-table th.el-table__cell {
        background: #f5edde;
        color: #333;
        font-size: 16px;
      }
    }
  }
}
</style>
