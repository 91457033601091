<template>
  <div class="view-preview-lot-list">
    <div class="search-list">
      <div class="search-list-1">
        <input type="text" class="inp-lot" placeholder="请输入Lot号搜索" v-model="liveAuctionItemListData.lot">
        <button class="search-data" @click="searchLot()">搜索</button>
      </div>
      <div class="search-list-2">
        <span class="page-show">每页显示</span>
        <el-select v-model="value" placeholder="请选择" @change="changePage">
          <el-option v-for="item in options" :key="item.id" :label="item.num" :value="item.num">
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="middle-table">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column width="45" align="center">
          <template slot-scope="scope">
            <input type="checkbox" v-model="scope.row.checked" :value="scope.row.id">
          </template>
        </el-table-column>
        <el-table-column prop="lot" label="Lot号" align="center" width="40">
        </el-table-column>
        <el-table-column label="图片" align="center">
          <template slot-scope="scope">
            <el-image class="table-td-thumb" :src="scope.row.hostImage+imgSize(300)"
              :preview-src-list="[scope.row.hostImage]">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="title" label="拍品名称" align="center" width="70">
          <template slot-scope="scope">
            <router-link target='_blank' :to="{path:'/productdetails',query:{productdetailsId:scope.row.id}}"
              style="color:#606266">{{scope.row.title}}</router-link>
          </template>
        </el-table-column>
        <el-table-column label="拍品描述" width="140" align="center">
          <template slot-scope="scope">
            <p class="more-line">{{scope.row.itemOverview}}</p>
          </template>
        </el-table-column>
        <el-table-column prop="initialPrice" label="起拍价" align="center">
        </el-table-column>
        <el-table-column prop="minimumValuation" label="最低估价" align="center">
        </el-table-column>
        <el-table-column prop="highestValuation" label="最高估价" align="center">
        </el-table-column>
        <el-table-column prop="drop_hammer_price" label="落槌价" align="center">
        </el-table-column>
        <el-table-column prop="brandAuthorName" label="品牌/作者" align="center">
        </el-table-column>
        <el-table-column prop="stylePeriodName" label="风格/时期" align="center">
        </el-table-column>
        <el-table-column prop="materialsCraftName" label="材料/工艺" align="center">
        </el-table-column>
        <el-table-column prop="classifyParentName" label="一级分类" align="center">
        </el-table-column>
        <el-table-column prop="classifyName" label="二级分类" align="center">
        </el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">
            <span>{{scope.row.itemAuctionStatus === 1 ?'待拍':scope.row.itemAuctionStatus === 2?'竞拍中':scope.row.itemAuctionStatus === 3?'成交':scope.row.itemAuctionStatus === 4?'流拍':scope.row.itemAuctionStatus === 5?'撤拍':''}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <i class="iconfont icon-bianji cd-gold" @click="editLotOne(scope.row)"></i>
            <i class="iconfont icon-shanchu1 cd-gold" @click="allDel(scope.row.id,1)"></i>
          </template>
        </el-table-column>
      </el-table>
      <div class="but-bottom-1">
        <div class="but-bottom-left-2">
          <input type="button" class="btn-1" v-model='checked' @click="checkAll()">
          <input type="button" class="btn-1" @click="checkAnti()" v-model="checkedF">
          <button class="btn-1" @click="allDel()">批量删除</button>
          <button class="btn-1" @click="addLotOne()">新增单个拍品</button>
          <button class="btn-1" @click="reEstablishList(3)">重新上传拍品文件</button>
          <button class="btn-1" @click="reEstablishList(4)">重新上传图片文件</button>
        </div>
        <div class="el-pag-ination">
          <el-pagination background @current-change="handleCurrentChange" :page-size="liveAuctionItemListData.pageSize"
            :total="total" :current-page="liveAuctionItemListData.page" layout="total, prev, pager, next">
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 新增单个拍品 -->
    <preview-lot-list-dia-log ref="PreviewLotListDiaLog" :editLotId='editLot' @addLot='addLot' />
    <!-- 编辑单个拍品 -->
    <edit-preview-lot-list-dialog ref="EditPreviewLotListDialog" :editData='editData' @addLot='addLot' />
  </div>
</template>
<script>
  import {
    liveAuctionItemList,
    delAuctionItem
  } from '../../../../api/index'
  import PreviewLotListDiaLog from './Dialog/Previewlotlistdialog'
  import EditPreviewLotListDialog from './Dialog/Editpreviewlotlistdialog'
  import {
    MessageBox
  } from 'element-ui'
  export default {
    name: 'PreviewLotList',
    components: {
      PreviewLotListDiaLog,
      EditPreviewLotListDialog
    },
    data() {
      return {
        liveAuctionItemListData: {
          auctionId: Number(localStorage.getItem('auctionId')),
          page: 1,
          pageSize: 10,
          userId: Number(localStorage.getItem('accessId')),
          authorization: localStorage.getItem('accessToken'),
          x_user_id: Number(localStorage.getItem('accessId')),
          lot: ''
        },
        total: 0,
        tableData: [],
        checkboxModel: [],
        checked: '全选',
        checkedF: '反选',
        options: [{
            id: 1,
            num: 10
          },

          {
            id: 2,
            num: 20
          },
          {
            id: 3,
            num: 25
          },
          {
            id: 4,
            num: 50
          }
        ],
        value: 10,
        editLot: 0,
        editData: {}
      }
    },
    created() {
      this.liveAuctionItemList()
    },
    // watch: {
    //   liveAuctionItemListData: {
    //     deep: true,
    //     handler (newValue, oldValue) {
    //       if (oldValue.lot === '') {
    //         this.liveAuctionItemList()
    //       }
    //     }
    //   }
    // },
    methods: {
      imgSize(sizeW, sizeh) {
        return `?x-oss-process=image/resize,m_pad,w_${sizeW},h_${sizeh||sizeW}`
      },
      async liveAuctionItemList() {
        const res = await liveAuctionItemList(this.liveAuctionItemListData)
        if (res.code === 0) {
          this.tableData = res.data.list
          if (this.tableData !== null) {
            for (let index = 0; index < this.tableData.length; index++) {
              this.$set(this.tableData[index], 'checked', false)
            }
          }
          this.total = res.data.count
          const params = {
            auctionName: res.data.auctionName,
            startTime: res.data.startTime
          }
          this.$emit('liveAuctionItemListTitle', params)
        } else {
          this.$message.error(res.msg)
        }
      },
      addLot() {
        this.liveAuctionItemList()
      },

      // 点击搜索lot
      searchLot() {
        this.liveAuctionItemListData.page = 1
        this.liveAuctionItemList()
      },
      // 切换分页
      handleCurrentChange(page) {
        this.liveAuctionItemListData.page = page
        this.liveAuctionItemList()
      },
      checkAnti() {
        this.tableData.forEach(item => {
          item.checked = !item.checked
        })
      },
      checkAll() {
        this.tableData.forEach(item => {
          item.checked = true
        })
      },
      // 批量删除
      allDel(id, index) {
        this.checkboxModel = []
        for (let index = 0; index < this.tableData.length; index++) {
          if (this.tableData[index].checked === true) {
            this.checkboxModel.push(this.tableData[index].id)
          }
        }
        if (index !== 1) {
          if (this.checkboxModel.length === 0) {
            this.$message.error('请选择要删除的拍品')
            return
          }
        }
        MessageBox.confirm('确定要删除吗？', '提示', {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning'
          })
          .then(async () => {
            const res = await delAuctionItem({
              itemIdStr: this.checkboxModel.toString() || id.toString(),
              x_user_id: Number(localStorage.getItem('accessId')),
              authorization: localStorage.getItem('accessToken')
            })
            if (res.code === 0) {
              this.$message.success('删除成功')
              const delNum = this.checkboxModel.length || 1 // length为0时单选反之全选
              const yushu = this.total % this.liveAuctionItemListData.pageSize // 余数
              const endPageSize = yushu || this.liveAuctionItemListData.pageSize // 最后一页多少条数据
              if (this.liveAuctionItemListData.page > 1 && delNum >= endPageSize) {
                // 大于一页且删除数量大于等于最后一页就查询上一页的数据
                this.liveAuctionItemListData.page--
              }
              this.liveAuctionItemList()
            } else {
              this.$message.error(res.msg)
            }
          })
          .catch(() => {})
      },
      // 重新上传
      reEstablishList(index) {
        localStorage.setItem('reEstablishId', index)
        localStorage.setItem('isNew', 'false')
        this.$router.push({
          path: '/auctionpersonalcenter',
          name: 'Auctionpersonalcenter'
        })
        localStorage.setItem('auctionId', this.tableData[0].auctionId)
      },
      // 每页显示
      changePage(page) {
        this.liveAuctionItemListData.page = 1
        this.liveAuctionItemListData.pageSize = page
        this.liveAuctionItemList()
      },
      // 新增单个拍品
      addLotOne() {
        this.$nextTick(() => {
          this.$refs.PreviewLotListDiaLog.open()
        })
      },
      // 编辑单个拍品
      editLotOne(data) {
        this.editData = data
        this.$nextTick(() => {
          this.$refs.EditPreviewLotListDialog.open()
        })
      }
    }
  }
</script>
<style lang="less" scoped>
  .view-preview-lot-list {
    background: #fff;
    padding-top: 20px;

    .more-line {
      // width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      margin-bottom: 0;
    }

    .search-list {
      display: flex;
      padding: 0 40px;
      justify-content: space-between;
      align-items: center;

      .search-list-1 {
        display: flex;
        align-items: center;

        .inp-lot {
          outline: none;
          width: 214px;
          height: 40px;
          background: #fff;
          border-radius: 8px;
          border: 1px solid #bdc3c7;
          font-size: 14px;
          color: #999;
          padding-left: 46px;
          background: url("../../../../assets/images/sousuo@2x.png") no-repeat;
          background-size: 16px;
          background-position-x: 21px;
          background-position-y: 10px;
        }

        .search-data {
          width: 80px;
          height: 40px;
          background: #cda156;
          border-radius: 8px;
          text-align: center;
          line-height: 40px;
          color: #fff;
          border: none;
          font-size: 16px;
          margin-left: 10px;
        }
      }

      .search-list-2 {
        .page-show {
          font-size: 18px;
          color: #333;
          font-weight: 400;
          padding-right: 20px;
        }

        .el-input__inner {
          width: 120px;
          height: 40px;
          border-radius: 0;
          border: 1px solid #bec3c6;
        }
      }
    }

    .middle-table {
      padding: 0 20px;
      margin-top: 20px;

      /deep/ .el-table th.el-table__cell {
        background: rgba(245, 234, 204, 0.5);
      }

      /deep/ .el-table__header-wrapper {
        border-radius: 29px 29px 0px 0px !important;
      }

      /deep/ .el-table thead {
        color: #333333;
        font-size: 16px;
      }

      .red {
        color: #cda156;
        font-size: 16px;
        text-decoration: underline;
      }
    }

    .but-bottom-1 {
      padding: 20px 0 44px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .but-bottom-left-2 {
        .btn-1 {
          padding: 0 19px;
          border-radius: 8px;
          height: 36px;
          background: #b38d4b;
          text-align: center;
          color: #fff;
          border: none;
          font-size: 16px;
          margin-right: 10px;
        }
      }

      .el-pag-ination {
        text-align: right;
      }
    }

    /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #cda156;
      color: #fff;
    }

    .cd-gold {
      color: #cda156;
      font-size: 20px;
      padding-right: 20px;
    }
  }
</style>
